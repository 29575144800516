// Brand colors
$primary: #0A24CC;
$secondary: #051B30;
$tertiary: #EC4300;
$success: #005B19;

// Brand colors by name
$masBlue: #0A24CC;
$spaceBlue: #051B30;
$redOrange: #EC4300;

// fills
$blue-fill: #EEF0FB;
$orange-fill: #FFF7F1;
$green-fill: #EBFBF0;
$yellow-fill: #FFF4CE;

// I don't know
$yellow: #705A00;

// Grayscale
$white: white;
$black: #000;

$slate: #515151;
$slate-dark: #111111;
$slate-dark-opaque: rgba($slate-dark, 0.75);
$slate-dark-disabled: rgba($slate-dark, 0.3);
$slate-medium: #707070;
$slate-light: #909090;
$dove: #A3A3A3;
$dove-medium: #DBDBDB;
$dove-light: #DFDFDF;
$dove-xlight: #F7F7F7;

// Contextual
$error-message: #D13030;
$error-bg: #FFE7DD;
$warning: #ffcc00;
