@use '~@mablemarket/common-react-lib/build/styles/variables' as vars;
@use '~@mablemarket/common-react-lib/build/styles/mixins' as mixins;
@use '~@mablemarket/common-react-lib/build/styles/colors' as colors;
@use '~@mablemarket/common-react-lib/build/styles/responsive' as resp;

.container {
  p {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  a {
    color: colors.$slate;
  }

  strong {
    font-weight: vars.$font-weight-bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul, ol {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
